import PropTypes from "prop-types"
import React, { Component, useState } from 'react'

import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Content from "../../content/global/global.json"

import {TimelineMax, TweenMax, Power2 } from 'gsap';

import LogoWhite from "../assets/logo-white.svg"
import LogoBlack from "../assets/logo-black.svg"

import ProjectList from "../components/projects-data"
import ProjectSS from "../components/project-slideshow"

import LbSlider from "../components/lightbox-slideshow"

import "../components/global.scss";

export default class IndexPage extends Component {

	constructor(props) {
    super(props);

    this.layout = React.createRef();
    this.introPanel = React.createRef();

    this.state = { mouseX: 0, mouseY: 0, radius: 10, animState: 'pristine', currentProj: '0', ssPhotoIndex: 0, ssIsOpen: false };
  }

  componentDidMount() {
    var _self = this;

  this.introAnim = () => {

      this.expandCircle = document.querySelectorAll('.expand-circle');
      TweenMax.set(this.expandCircle[0], {attr:{r:10}});
      let introFadeOut = new TimelineMax();
      introFadeOut.to(this.expandCircle[0], 1.2,{ attr:{r:3000}, ease: Power2.easeIn}, 0);
      setTimeout(function(){ _self.lockAnim()}, 1200);
      document.body.classList.add("hero-clicked");
    }
    if((navigator.userAgent.toLowerCase().search('safari') > -1) && !(navigator.userAgent.toLowerCase().search('chrome') > -1) && !(navigator.userAgent.toLowerCase().search('crios') > -1)) {
      this.introAnim();
    }
  }

  _onClick(e) {
    if (this.state.animState == 'pristine'){
      this.introAnim();
    }
  }

  _onMouseMove(e) {
    if (this.state.animState == 'pristine'){
      this.setState({ mouseX: e.clientX, mouseY: e.clientY });
    }
  }

  updateSliderId(e){
    if (e && e.nativeEvent.type == 'click'){
        let dataId = e.currentTarget.getAttribute('data-id');
        setTimeout(function() {
          this.setState({currentProj: dataId});
        }.bind(this), 250);

        let slider = document.getElementsByClassName('project-slider');
        let slideUpdate = new TimelineMax();

        slideUpdate.to(slider, 0.25, { opacity: 0}).to(slider, 0.25, { opacity: 1});

    } else {
      if (!document.body.classList.contains('project-loaded')){
        if(e && e.nativeEvent.type == 'mouseover'){
          let dataId = e.currentTarget.getAttribute('data-id');
          setTimeout(function() {
            this.setState({currentProj: dataId});
          }.bind(this), 250);

          let slider = document.getElementsByClassName('project-slider');
          let slideUpdate = new TimelineMax();

          slideUpdate.to(slider, 0.25, { opacity: 0}).to(slider, 0.25, { opacity: 1});
        } else if (e && e.nativeEvent.type == 'mouseout'){
          if (document.body.classList.contains('project-loaded')){
            let dataId = e.currentTarget.getAttribute('data-id');
            let loadedId = document.body.getAttribute('data-loaded-id');
            if (!(loadedId == dataId)){
              setTimeout(function() {
                this.setState({currentProj: loadedId});
              }.bind(this), 250);
              let slider = document.getElementsByClassName('project-slider');
              let slideUpdate = new TimelineMax();

              slideUpdate.to(slider, 0.25, { opacity: 0}).to(slider, 0.25, { opacity: 1});
            }
          }
        }
      }
    }
  }

  launchLightbox(e){
    const photoId = e.target.dataset.img_index;
    this.setState({ ssIsOpen: true });
    this.setState({ ssPhotoIndex: photoId });
  }

  closeLightbox(e){
    if (e.target.nodeName != 'IMG' && e.target.nodeName != 'BUTTON'){
      this.setState({ ssIsOpen: false });
    }
  }

  lockAnim = () => {
    this.setState({animState: 'locked'});
  }

  colorUpdate(){
    if (document.body.classList.contains('hero-clicked')){
      let elem = document.getElementById('hero-content');
      elem.style.backgroundColor = '#fff';
    }
  }

  handleMouseLeave(e){
    if(document.body.classList.contains('hero-clicked')){
      if(document.body.getAttribute('data-project-color') && document.body.getAttribute('data-project-color') != 'ffffff'){
        let oldColor = document.body.getAttribute('data-project-color');
        var elem = document.getElementById('hero-content');
        elem.style.backgroundColor = "#" + oldColor;
      }
    }
  }

  homeContent(){
    if (document.body.classList.contains('hero-clicked')){
      let homeElem = document.getElementsByClassName('home-item-elem');

      if (homeElem[0].classList && !homeElem[0].classList.contains('active')){
        homeElem[0].classList.add('active');
      } else {
        homeElem[0].classList.remove('active');
      }

      if (document.body.classList.contains('slideshow-on')){
        document.body.classList.remove('slideshow-on');
      }
      document.body.classList.remove('project-loaded');
      document.body.setAttribute('data-project-color', 'ffffff');
      // disable other projects  
      let projects = document.getElementsByClassName('project');
      let footerElem = document.getElementsByClassName('footer-item-elem');
      footerElem[0].classList.remove('active');
      if(projects != []){
        for (let project of projects) {
          project.classList.remove('active');
        }
      }
    }
  }

  footerContent(){
    if (document.body.classList.contains('hero-clicked')){
      let footerElem = document.getElementsByClassName('footer-item-elem');
      if (footerElem[0].classList && !footerElem[0].classList.contains('active')){
        footerElem[0].classList.add('active');
      } else {
        footerElem[0].classList.remove('active');
      }

      if (document.body.classList.contains('slideshow-on')){
        document.body.classList.remove('slideshow-on');
      }
      document.body.classList.remove('project-loaded');
      document.body.setAttribute('data-project-color', 'ffffff');
      // disable other projects  
      let projects = document.getElementsByClassName('project');
      let homeElem = document.getElementsByClassName('home-item-elem');
      homeElem[0].classList.remove('active');
      if(projects != []){
        for (let project of projects) {
          project.classList.remove('active');
        }
      }
    }
  }

  render() {
    const { mouseX, mouseY, currentProj, ssPhotoIndex, ssIsOpen } = this.state;
  	return (
  	  <Layout>
		    <SEO title="Home" />

          {ssIsOpen && (
            <div className="lighboxslider" onClick={this.closeLightbox.bind(this)} >
              <LbSlider pid={this.state.currentProj} onClick={this.closeLightbox.bind(this)} initialSlide={this.state.ssPhotoIndex}/>
              <div className="lb-close" onClick={this.closeLightbox.bind(this)}>X</div>
            </div>
          )}

          <div className={`section state-` + this.state.animState} id="section-1">

            <div className="hero-wrapper intro" ref={this.introPanel}>
              <div className="row">
                <div className="row-inner">
                  <div className="col col-left">
                    <LogoWhite />
                  </div> 
                  <div className="col col-right"></div>
                </div>
              </div>
            </div>

            <div className="hero-wrapper hero-content" onClick={this._onClick.bind(this)} onMouseMove={this._onMouseMove.bind(this)}>

              <svg className="clipping-mask">
                <defs>
                  <clipPath id="clipping">
                    <circle cx={this.state.mouseX} cy={this.state.mouseY} r={this.state.radius} className="expand-circle"/>
                  </clipPath>
                </defs>
              </svg>


              <div className="row hero-content-inner" id="hero-content">
                <div className="row-inner">
                  <div className="col col-left">
                    <LogoBlack />
                    <div className="project-slider">
                      <ProjectSS pid={this.state.currentProj} launchLightbox={this.launchLightbox.bind(this)}/>
                    </div>

                  </div>
                  <div className="col col-right">
                    <div className='home-item-elem active' onMouseEnter={this.colorUpdate} onMouseLeave={this.handleMouseLeave}>
                      <div className='elem-title-logo' onClick={this.homeContent}>
                        <LogoBlack />
                      </div>
                      <div className='elem-title' onClick={this.homeContent}>ABOUT</div>
                      <div className='elem-body' dangerouslySetInnerHTML={{__html:Content.body}} />
                    </div>
                    <ProjectList pid={this.state.currentProj} updateSlider={this.updateSliderId.bind(this)} />
                    <div className='footer-item-elem' onMouseEnter={this.colorUpdate} onMouseLeave={this.handleMouseLeave}>
                      <div className='elem-title' onClick={this.footerContent}>Contact</div>
                      <div className='elem-body'>
                        <div className="footer-email"><a href="mailto:contact@mtcs-studio.com">contact@mtcs-studio.com</a></div>
                        <div className="footer-phone"><a href="https://www.instagram.com/morethancommonsense/">instagram</a></div>
                      </div>
                    </div>
                  </div> 
                </div> 
              </div>

            </div>
		      </div>
		  </Layout>
	  )
  }
}
