import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

import Slider from "react-slick";

const lbsettings = {
  dots: false,
  infinite: false,
  arrow: true,
  fade: false,
  accessibility: false,
  slidesToShow: 1,
  slidesToScroll: 1
};

const LbSlider = ({ data, pid, initialSlide }) => (
  <div className='lighbox-slider'>                
    <div className='ss-image-list'>
      <Slider {...lbsettings} initialSlide={initialSlide}>
        {data.allProjectJson.edges[pid].node.images.map(function(pimage, imgindex){
          return <div className='ss-image' key={imgindex}><img src={pimage.image} alt={data.allProjectJson.edges[pid].node.title} /></div>;
        })}
      </Slider>
    </div>
  </div>

)

export default function PSS(props) {
  return (
    <StaticQuery
      query={graphql`
        query{
          allProjectJson (sort: { order: DESC, fields: position }){
            edges {
              node {
                fields {
                  slug
                }
                title
                position
                body
                projectcolor
                images {
                  image
                }
              }
            }
          }
        }
      `}
      render={data => <LbSlider data={data} pid={props.pid} {...props} />}
    />
  )
}