
// ALL DATA:
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

import Slider from "react-slick";

// Example settings to use for Slick
var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

function handleMouseLeave(e){
  if (document.body.classList.contains('hero-clicked')){
    if (!document.body.classList.contains('project-loaded')){
      // If no project is loaded => turn slideshow off
      if (document.body.classList.contains('slideshow-on')){
        document.body.classList.remove('slideshow-on');
      }
    } else {
      // TODO: If a project is loaded => turn back into previous loaded project's slideshow
    }
  }
}

function handleHover(e) {
  if (document.body.classList.contains('hero-clicked')){
    // Toggle slideshow on hover
    if (!document.body.classList.contains('slideshow-on')){
      document.body.classList.add('slideshow-on');
    } 
  }
}

function toggleActive(e) {
  if (document.body.classList.contains('hero-clicked') && e.target.nodeName != 'BUTTON' && e.target.nodeName != 'IMG'){
    // If element clicked is already active, de-active it
    if (e.currentTarget.classList.contains('active')){
      //Setting color to white
      var elem = document.getElementById('hero-content');
      elem.style.backgroundColor = "#ffffff"
      document.body.setAttribute('data-project-color', 'ffffff');

      document.body.classList.remove('project-loaded');

      // Toggling off All projects
      let projects = document.getElementsByClassName('project');

      if(projects != []){
        for (let project of projects) {
          project.classList.remove('active');
        }
      }
    } else {
      //Setting color to the content area
      let pColor = e.currentTarget.getAttribute('data-color-project');
      var elem = document.getElementById('hero-content');
      elem.style.backgroundColor = "#" + pColor.replace('#', '');

      // Setting Loaded project if into body attribute
      let loadedId = e.currentTarget.getAttribute('data-id');
      document.body.setAttribute('data-loaded-id', loadedId);

      // Setting the site to this color through body attribute
      document.body.setAttribute('data-project-color', pColor.replace('#', ''));
      if (!document.body.classList.contains('project-loaded')){
        document.body.classList.add('project-loaded');
      }

      // Toggling Home / footer / other projects off
      let homeElem = document.getElementsByClassName('home-item-elem');
      let footerElem = document.getElementsByClassName('footer-item-elem');
      let projects = document.getElementsByClassName('project');

      if (homeElem[0].classList)
        homeElem[0].classList.remove('active');

      if (footerElem[0].classList)
        footerElem[0].classList.remove('active');

      if(projects != []){
        for (let project of projects) {
          project.classList.remove('active');
        }
      }

      // Setting current project as active
      e.currentTarget.classList.add('active');
    }
  }
}

const ProjectList = ({ data, updateSlider }) => (

    <div className={'project-list'}>
      {data.allProjectJson.edges.map(({ node }, index) => (    
        <div className='project' key={index} data-color-project={node.projectcolor} onMouseEnter={handleHover} onMouseLeave={handleMouseLeave} data-id={index} onClick={toggleActive}>
          <div className={'project-title'} data-id={index} onMouseOver={updateSlider} onMouseLeave={updateSlider} onClick={updateSlider}>{node.title}</div>
          <div className={'project-body'}>
            <div>{node.body}</div>
            <div className='project-images'>
              
              <div className='image-list'>
                <Slider {...settings}>
                  {node.images.map(function(pimage, imgindex){
                    return <div className='image' key={imgindex}><img src={pimage.image} alt={node.title} /></div>;
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
)

export default function PL(props) {
  return (
    <StaticQuery
      query={graphql`
        query{
          allProjectJson (sort: { order: DESC, fields: position }){
            edges {
              node {
                fields {
                  slug
                }
                title
                position
                body
                projectcolor
                images {
                  image
                }
              }
            }
          }
        }
      `}
      render={data => <ProjectList data={data} updateSlider={props.updateSlider} {...props} />}
    />
  )
}



