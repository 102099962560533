import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

import Slider from "react-slick";

const settings = {
  accessibility: false,
  adaptiveHeight: true,
  arrows: true,
  fade: true,
  responsive: [
    {
      breakpoint: 920,
      settings: {
        arrows: false,
        adaptiveHeight: true,
      }
    },
  ]
};

const ProjectSS = ({ data, pid, launchLightbox }) => (
  <div>
    <div className='project-images'>                
      <div className='image-list'>
        <Slider {...settings}>
          {data.allProjectJson.edges[pid].node.images.map(function(pimage, imgindex){
            return <div className='image' key={imgindex}><img src={pimage.image} data-img_index={imgindex} onClick={launchLightbox} alt={data.allProjectJson.edges[pid].node.title} /></div>;
          })}
        </Slider>
      </div>
    </div>
  </div>
)

export default function PSS(props) {
  return (
    <StaticQuery
      query={graphql`
        query{
          allProjectJson (sort: { order: DESC, fields: position }){
            edges {
              node {
                fields {
                  slug
                }
                title
                position
                body
                projectcolor
                images {
                  image
                }
              }
            }
          }
        }
      `}
      render={data => <ProjectSS data={data} pid={props.pid} {...props} />}
    />
  )
}